@import '../../styles/index.scss';

.hfCard {
  & :global(.card) {
    border: none;
    box-shadow: $card-box-shadow;
    margin-bottom: $grid-gutter-width;

    :global(.card-drop) {
      font-size: 20px;
      line-height: 0;
      color: inherit;
    }

    :global(.header-title) {
      margin-bottom: 0.5rem;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      font-size: $font-size-base;
      margin-top: 0;
    }

    :global(.card-widgets) {
      float: right;
      height: 16px;

      > a,
      :global(.card-action) {
        color: inherit;
        font-size: 18px;
        display: inline-block;
        line-height: 1;

        &:global(.collapsed) {
          i {
            &:before {
              content: '\F415';
            }
          }
        }
      }
    }
  }

  // Card title / Card Header
  & :global(.card-title),
  & :global(.card-header) {
    margin-top: 0;
  }

  //Card disable loading (Custom Cards)
  & :global(.card-disabled) {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $card-border-radius;
    background: rgba($white, 0.8);
    cursor: progress;
  }
}

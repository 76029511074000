@import '../../../styles/variables';

.container {
  display: block;

  .sectionTitle {
    margin: 24px 0px;
    font: $large-label;
    color: $gray800;
  }

  .leftSide {
    padding: 0px;
    border-right: 1px solid $gray300;

    .listItemActive {
      color: $gray800 !important;
      font-weight: bold;
    }

    .listItem {
      display: flex;
      align-items: center;
      padding: 26px 16px;
      border-radius: 0px !important;
      border: none !important;
      border-bottom: 1px solid $gray300 !important;
      cursor: pointer;
      color: $gray500;

      .listItemIcon {
        padding-right: 16px;

        &::before {
          font-size: 24px;
          max-width: 24px;
        }
      }
    }
  }

  .rightSide {
    padding: 40px;
  }
}

@import '../../../../../../../styles/variables';

.modalContent {
  .modalBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      margin: 24px 0;
    }

    p {
      margin: 0;
    }
  }

  .modalFooter {
    display: flex;
    justify-content: center;

    margin-top: 24px;

    button {
      background-color: $primary;
      border-color: $primary;
    }
  }
}

.image {
  margin: 12px;
  padding: 16px;
  background: var(--white) 0% 0% no-repeat padding-box;
  background: $white-background;
  border: 1px solid $primary;
  border-radius: 4px;
  opacity: 1;
}

.noPadding {
  padding-left: 0px;
  padding-right: 0px;
}

.infoButton {
  color: $info !important;
  margin-right: auto !important;
  outline: none!important;
  box-shadow: none!important;
  border: none!important;
  &:hover, &:active {
    outline: none!important;
    box-shadow: none!important;
    color: $info !important;
  }
}
.hintText {
  color: $gray500;
  text-align: left;
  font: $small-paragraph;
  letter-spacing: 0px;
}

.error {
  border: 1px solid red;
}

.disabledInput {
  pointer-events: none;
  background: #e9ecef;
}

.padding {
  padding-left: 20px;
}

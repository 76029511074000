@import '../../../../../styles/variables';

.selectRoleButtonGroup {
  display: flex;
  justify-content: space-between;
}

.selectRoleButton {
  width: 167px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;

  i::before {
    font-size: 24px;
    max-width: 24px;
    margin-right: 0.5rem;
  }
}

.selectRoleButtonChosen {
  background-color: $primary;
  color: $white;
}

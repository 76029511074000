@import '../../../../../../../styles/variables';

.container {
  padding-left: 12px;
  padding-right: 12px;

  .label {
    padding-top: 16px;
    color: $gray800;
  }

  .warning {
    padding-bottom: 4px;
    color: $danger;
  }

  .controlSection {
    margin-top: 20px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .searchSection {
      width: 140px;
      display: flex;
      flex-direction: row;

      .iconStyle {
        position: absolute;
        margin-top: 9px;
        margin-left: 16px;
      }

      .inputStyle {
        padding-left: 35px;
      }
    }

    .buttonSection {
      .iconStyle {
        font-size: 12px;
        align-self: center;
        padding-right: 4px;
        color: $white;
      }
    }
  }

  .tableContainer {
    padding-left: 0px;
    padding-right: 0px;

    table {
      thead {
        background-color: $gray200;
        display: none;
      }
      tbody {
        tr {
          td {
            width: 19.65%;
            padding-top: 0px;
            padding-bottom: 0px;
            background-color: $teriary;

            &:first-child {
              width: max-content;
            }
          }
        }
      }
    }

    th {
      font: $small-label-semi-bold;
      color: $gray800 !important;
      padding-top: 24px;
      padding-bottom: 24px;
    }

    .tableTitle {
      color: $gray700;
      font-weight: bold;
      padding-bottom: 16px;
    }

    .tableRows {
      display: flex;
      flex-direction: row;
      color: $gray500;

      padding-top: 16px;
      padding-bottom: 16px;

      .rowLabel {
        font-family: Nunito;
        font-weight: bold;
        font-size: 14px;
      }

      .iconStyle {
        font-size: 16px;
        padding-right: 8px;
        transition: 0.5s;
        color: $gray500;
      }
    }
  }
}

.remain {
  font-size: 12px;
  color: $gray500;
  white-space: nowrap;
  font-family: Nunito;
}

.addButton {
  width: 32px;
  height: 32px;
  color: white;
  border-radius: 4px;
  background-color: $primary;
  border: none;
  font-size: 21px;
}

.disabledButton {
  background-color: $gray300;
}

@import '../../../../../styles/variables';

.rememberMeWrapper {
  color: $primary;
}

.icon {
  &::before {
    font-size: 16px;
    max-width: 16px;
    margin-right: 0.5rem;
  }
}

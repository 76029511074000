@import '../../../../../../../styles/variables';

.container {
  padding-left: 12px;
  padding-right: 12px;

  .info {
    margin-top: 24px;
    > span {
      > i {
        color: $info;
        margin-right: 4px;
        ::before {
          font-size: 16px;
        }
      }
    }
  }

  .warning {
    padding-top: 16px;
    color: $danger;
  }

  .controlSection {
    margin-top: -20px;
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .searchSection {
      width: 140px;
      display: flex;
      flex-direction: row;

      .iconStyle {
        position: absolute;
        margin-top: 9px;
        margin-left: 16px;
      }

      .inputStyle {
        padding-left: 35px;
      }
    }

    .buttonSection {
      .iconStyle {
        padding-right: 4px;
        color: $white;
      }
    }
  }

  .tableContainer {
    padding-left: 0px;
    padding-right: 0px;

    table {
      thead {
        background-color: $gray200;
      }
      tbody {
        tr {
          td {
            padding-top: 16px;
            padding-bottom: 16px;
            font: $paragraph;
            letter-spacing: 0px;
            color: $gray700;
          }
        }
      }
    }

    th {
      font: $small-label-semi-bold;
      color: $gray800 !important;
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
}

.column {
  &:first-child {
    padding-left: 20px;
    width: 25%;
  }

  &:not(:first-child):not(:last-child) {
    width: calc((100% - 25% - 80px) / 4);
  }

  &:last-child {
    width: 80px;
    padding-left: 0;
  }

  .iconStyle {
    padding-right: 8px;
    color: $primary;
    width: 32px;
    height: 24px;
  }

  .editIcon {
    color: #999999;
    &::before {
      font-size: 24px;
    }
    cursor: pointer;
  }

  .trashIcon {
    color: #999999;
    &::before {
      font-size: 24px;
    }
    cursor: pointer;
    margin-left: 5px;
  }
}

@import '../../styles/variables';

.title {
  font: $large-label;
  letter-spacing: var(--character-spacing-0);
  color: $gray800;
  text-align: left;
  &.small {
    font: $paragraph;
  }
  &.hasMarginTop {
    display: block;
    margin-top: 24px;
  }
  &.smallMargin {
    display: block;
    margin-top: 8px;
  }
}

.requiredSymbol {
  color: $danger;
}

.block {
  display: block;
}

@import '../../styles/variables';

.wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;

  .content {
    background-color: $gray200;
    position: relative;
  }
}

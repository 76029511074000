@import '../../../../../../../styles/variables';

.container {
  display: flex;
  flex-direction: row;
  padding-left: 4px;
  padding-top: 18px;
  padding-bottom: 18px;
  font-family: Nunito;
  font-size: 16px;

  .column {
    &:nth-child(3) {
      width: 14.5% !important;
    }

    &:nth-child(5) {
      margin-right: 40px;
      margin-left: 10px;
    }

    &:first-child {
      padding-left: 20px;
      width: max-content;
    }

    &:not(:first-child):not(:last-child) {
      width: calc((100% - 25% - 80px) / 4);
    }

    &:last-child {
      width: 80px;
      padding-left: 0;
    }

    .iconStyle {
      padding-right: 8px;
      color: $primary;
      width: 32px;
      height: 24px;
    }

    .editIcon {
      color: #999999;
      font-size: 20px;
      cursor: pointer;
    }

    .trashIcon {
      color: #999999;
      font-size: 20px;
      cursor: pointer;
      margin-left: 5px;
    }
  }

  .timeStyle {
    color: $primary;
  }
}

.even {
  align-items: center;
  background-color: $white !important;
}

.odd {
  align-items: center;
  background-color: $white !important;
}

.compact {
  width: 100px;
  padding-left: 30px;
  margin-left: 137px;
}

.dateCompact {
  padding-left: 20px;
  white-space: nowrap;
  margin-right: 0px !important;
}

.priceCompact {
  padding-left: 30px;
}

.nameCompact {
  padding-left: 40px;
}

.dateBlock {
  text-align: center;
  color: black;
}

.timeBlock {
  text-align: center;
}

.current {
  background-color: #d1e7dd80 !important;
  border: 1px solid #e6e5e5ad;
}

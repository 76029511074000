@import '../../../../styles/variables';
.modalHeader {
  padding-top: 1rem;
}

.modalHeaderTextStyle {
  font: $paragraph;
  color: $primary;
  cursor: pointer;
}

.modalHeaderButtonStyle {
  display: flex;
  justify-content: flex-end;
}

.dividerStyle {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 1rem;

  div {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@import '../../../../../styles/variables';

.container {
  padding: 0rem;

  .row {
    width: 100%;
  }
}

.saveButtonRow {
  display: flex;
  margin-bottom: 8rem;
  justify-content: flex-end;

  .saveButton {
    color: $white;
    background-color: $primary;
    padding: 0.5rem 2.5rem;
  }
}

.saveButtonRowEmpty {
  justify-content: center;
}

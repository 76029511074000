@import '../../../styles/index.scss';

.container {
  margin-top: 8px;
  width: 100%;

  font: $small-label-semi-bold;
  letter-spacing: 0px;
  color: $gray700;

  [class*='menu'] {
    [class*='is-focused'] {
      background: #eeeeee 0% 0% no-repeat padding-box;
    }
    [class*='is-selected'] {
      background: #eeeeee 0% 0% no-repeat padding-box;
      color: $gray700;
    }
  }
}

.fieldLabel {
  font-size: 24px;
}

.dropdown {
  margin-top: 6px;
}

.required {
  color: $danger;
}

.noLabel {
  .fieldLabel {
    padding-top: 22px;
    display: block;
  }
}

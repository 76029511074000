@import '../../../styles/variables';

.container {
  display: block;

  .filterBar {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 24px;

    .filterGroup {
      display: flex;

      .inputSearch {
        border-radius: 0px !important;
        width: 291px !important;
        border-right: 0px !important;
      }

      .inputSearchAddon {
        background-color: $white;
        border: 1px solid $gray300;
      }

      .filterButton {
        margin: 0px 16px;
        width: 141px !important;
      }
    }

    .createButton {
      background-color: $primary;
      color: $white;
    }
  }

  .tableViewWrapper {
    background-color: $white;
    padding: 24px;
  }
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}

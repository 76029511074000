@import '../../../styles/variables';

.title {
  font-weight: bold;
  font-size: 40px;
  color: $gray800;
  padding-bottom: 2.5rem;
}

.label {
  font-size: 16px;
  color: $gray800;
  margin-bottom: 0.5rem;
}

.selectRoleArea {
  margin-bottom: 1.5rem;
}

.continueButton {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  color: $white;
}

.disableContinueButton {
  background-color: $gray300;
}

.passwordInput {
  border-right: none;
  border-radius: 4px 0px 0px 4px !important;
}

.inputAppendIcon {
  background-color: $white;
  border-left: none;
  border-radius: 0px 4px 4px 0px !important;

  &:hover {
    cursor: pointer;
  }
}

.paragraph {
  font: $paragraph;
  letter-spacing: 0px;
  color: $gray800;
  margin-bottom: 1.5rem;
  b {
    font: normal normal bold 16px/24px Nunito;
  }
}

.email {
  color: $info;

  &:hover {
    cursor: pointer;
  }
}

@import '../../styles/index.scss';

@mixin button-shadow($color) {
  box-shadow: $btn-box-shadow rgba($color, 0.5);
}

.hfButton {
  &:global(.btn) {
    :global(.mdi) {
      &:before {
        line-height: initial;
      }
    }
  }

  &:global(.btn-secondary) {
    border: solid 1px $gray300;
    border-radius: 4px;
  }

  &:global(.btn-primary) {
    color: $white;
  }

  &:global(.btn-rounded) {
    border-radius: 2em;
  }

  // Button Shadows
  @each $color, $value in $theme-colors {
    &:global(.btn-#{$color}) {
      @include button-shadow($value);
    }
  }

  &:global(.btn-link) {
    box-shadow: none !important;
  }
}

.hfButton:focus {
  outline: 3px groove map-get($custom-colors, 'disable-grey');
}

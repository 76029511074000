@import '../../../../../../../styles/variables';

.infoButton {
  color: $info !important;
  margin-right: auto !important;
  outline: none!important;
  box-shadow: none!important;
  border: none!important;
  &:hover, &:active {
    outline: none!important;
    box-shadow: none!important;
    color: $info !important;
  }
}

.hintText {
  color: $gray500;
  text-align: left;
  font: $small-paragraph;
  letter-spacing: 0px;
}

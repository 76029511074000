.noSpecial {
  height: 54px;
  background: white;
  line-height: 54px;
  color: #999999;
  padding-left: 15px;
  font-family: Nunito;
  font-style: italic;
  font-size: 16px;
}

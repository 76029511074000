.loading {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  background: '#77777714';
  display: flex;
  justify-content: center;
  align-items: center;
}

@import '../../../../../../../../../styles/variables';

.checkCircleIcon {
  margin-bottom: 1.5rem;
  &::before {
    font-size: 40px;
    color: $success;
  }
}

.contentRow {
  justify-content: center;
}

.emailUs {
  color: $info;
}

@import '../../../../../../../styles/variables';

.rowMarginBottom {
  margin-bottom: 2.5rem;
}

.leftCol {
  padding: 0rem 0.75rem 0rem 0rem;
  display: flex;
  align-items: center;
}

.middleCol {
  padding: 0rem 0.75rem;
}

.rightCol {
  padding: 0rem 0rem 0rem 0.75rem;
}

.switcher {
  margin: 0rem;
  height: 100%;
  align-items: center;

  span {
    transform: none;
  }
}

.dateTimePicker {
  input {
    border-right: none !important;
  }
}

.dateTimePickerDisable {
  input {
    border-right: none !important;
    background-color: $gray200 !important;
  }

  span {
    color: $gray800 !important;
    border-color: $geyser !important;
    border-left: none !important;
    background-color: $gray200 !important;
  }
}

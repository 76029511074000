@import '../../../../../styles/variables';

.container {
  background-color: $white;
  padding: 24px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  border-radius: 4px;
  max-width: 1920px;

  .tableContainer {
    padding-left: 0px;
    padding-right: 0px;

    table {
      thead {
        background-color: $gray200;
      }
      tbody {
        tr {
          td {
            padding-top: 16px;
            padding-bottom: 16px;
            font: $paragraph;
            letter-spacing: 0px;
            color: $gray700;

            &:first-child {
              width: 80px;
              padding-right: 0;
            }

            &:nth-child(2) {
              padding-left: 0;
            }

            &:last-child {
              width: 80px;
              white-space: nowrap;
              padding-left: 0;
            }
          }
        }
      }
    }

    th {
      font: $small-label-semi-bold;
      color: $gray800 !important;
      padding-top: 24px;
      padding-bottom: 24px;
      &:nth-child(2) {
        padding-left: 0;
      }
    }

    .tableTitle {
      color: $gray700;
      font-weight: bold;
      padding-bottom: 16px;
      font: $heading6;

      .tableDate {
        color: $gray500;
        font-size: 13px;
        font: $small-label-semi-bold;
      }
      .tableDot {
        color: $gray500;
        word-spacing: 4px;
      }
    }


    .timeStyle {
      color: $primary;
      white-space: nowrap;
    }

    .dealType {
      padding-right: 8px;
      color: $primary;
      width: 32px;
      height: 24px;
    }

    .editIcon {
      color: #999999;
      font-size: 20px;
      cursor: pointer;
    }

    .trashIcon {
      color: #999999;
      font-size: 20px;
      cursor: pointer;
      margin-left: 5px;
    }
  }
}

@import '../../../../styles/variables';

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px;

  border: 1px solid #e2e2e2;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.paginationInfo {
  flex: 1;
  align-self: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.buttonContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.button {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  padding-top: 2px;
  cursor: pointer;
  background: white;
  border-radius: 4px;
  border: solid 1px #afafaf;
  font-size: 16px !important;

  &:hover {
    background-color: #afafaf;
  }

  &:focus {
    outline: none;
  }

  &.buttonStyleActive {
    background: $primary;
    border-radius: 4px;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.paginationTextStyle {
  padding-top: 6px;
  padding-right: 8px;
}

.paginationArrowTextStyle {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
}

.text {
  padding-top: 11px;
  text-align: center;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  display: block;
  &.textStyleActive {
    color: #ffffff;
  }
}

/*TODO: responsive later
@include bootstrap-respond($tablet-size) {
  .container {
    flex-direction: column;
    align-items: flex-end;
    border: none;
    padding: 16px 0;
  }

  .paginationInfo {
    margin-bottom: 16px;
    align-self: unset;
  }

  .button {
    &:last-child {
      margin-right: 0;
    }
  }
}
*/

@import '../../../styles/variables';

.container {
  display: inline-block;
  margin: 0 12px;
  width: 100%;
  height: 198px;
  background-color: white;
  text {
    font: normal normal normal 10px/10px Nunito;
    fill: #cccccc !important;
    opacity: 1;
  }
}

.tooltip {
  span:last-child {
    font-style: 700;
  }
  background-color: #4c4c4c;
  font: $small-paragraph;
  padding: 8px 16px;
  color: $white;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 4px;
  transform: translateX(calc(50% - 16px));
  &::after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: #4c4c4c transparent transparent transparent;
  }
}

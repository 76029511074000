@import '../../styles/variables';

.tagContainer {
  font-family: Nunito;
  font-weight: 600;
  padding: 4px 8px;
  width: fit-content;
  border-radius: 8px;
  white-space: nowrap;
}

.dinein {
  margin-right: 16px;
  color: $parsley-color;
  border: 1px solid #c3e6cb;
  background-color: #e5f4e9;
}

.takeout {
  border: 1px solid $colonial-white;
  background-color: $gin-fizz;
  color: $yukon-gold;
}

.wrapper {
  display: flex;
  justify-content: flex-start;
}
.inActive {
  color: #dedede;
  background-color: #f8f8f8;
  border: 1px solid #dedede;
}

.switcher {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
  span {
    margin-left: 8px;
    font: normal normal normal 16px/24px Nunito;
    color: #1f1f1f;
    transform: translateY(-4px);
  }
}

@import '../../styles/variables';

.layout {
  margin-bottom: 0px !important;
}

.header {
  cursor: pointer;
  font-weight: bold;

  div {
    pointer-events: none;
  }

  ul {
    margin-bottom: 0px;
  }
}

.active {
  color: $gray700;
  border-bottom: 2px solid $primary;
}

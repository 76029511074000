@import '../../styles/index.scss';

.prepend {
  position: absolute;
  z-index: 9;
  bottom: 12px;
  left: 12px;

  img {
    width: 15px;
    height: 15px;
  }
}

.postpend {
  position: absolute;
  z-index: 9;
  bottom: 12px;
  right: 12px;

  img {
    width: 15px;
    height: 15px;
  }
}

.input {
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

.hasPrepend {
  padding-left: 40px !important;
}

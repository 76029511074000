@import '../../../../../styles/variables';

.button {
  border-color: 1px solid $gray300;
  background-color: $white-background !important;
}

.tableViewButton {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.gridViewButton {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.highlightBorder {
  border-color: $primary !important;
  color: $primary !important;
  background-color: $white-background !important;
}

.highlightBorder:focus {
  border-color: $primary !important;
  color: $primary !important;
  background-color: $white-background !important;
}

.highlightBorder:active {
  border-color: $primary !important;
  color: $primary !important;
  background-color: $white-background !important;
}

@import '../../../../../../../../../styles/variables';

.noPadding {
  padding-left: 0px;
  padding-right: 0px;
}

.image {
  margin: 12px;
  padding: 16px;
  width: 72px;
  height: 72px;
  background: var(--white) 0% 0% no-repeat padding-box;
  background: $white-background;
  border: 1px solid $gray300;
  border-radius: 4px;
  opacity: 1;
}

.selected {
  border: 2px solid $primary;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  opacity: 0.9;
  background: #000000 0% 0% no-repeat padding-box;
}

.tooltip .tooltiptext {
  opacity: 0;
  width: 130px;
  background-color: black;
  color: $white;
  text-align: center;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 11px;
  font-family: Nunito;
  top: -39px;
  left: -11px;
  position: absolute;
  transition: 0.3s;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  opacity: 1;
}

@import '../../../../../styles/variables';

.container {
  background-color: $white;
  padding: 24px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 40px;
  border-radius: 4px;
}

@import '../../../../../../../styles/variables';

.specialForm {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border: 1px solid $gray300;
  border-radius: 4px;
  padding-top: 20px;
  margin-right: 0;
  margin-left: 0;
  .footer {
    text-align: center;
    .add {
      border: 1px solid $primary;
      margin: 16px 8px;
    }
    .cancel {
      margin: 16px 8px;
    }
  }
}

.iconStyle {
  &::before {
    font-size: 40px;
    color: $success;
  }
}

.image {
  margin: 12px;
  padding: 16px;
  width: 72px;
  height: 72px;
  background: var(--white) 0% 0% no-repeat padding-box;
  background: $white-background;
  border: 1px solid $gray300;
  border-radius: 4px;
  opacity: 1;
}

.selected {
  border: 1px solid $primary;
}

.noPadding {
  padding-left: 0px;
  padding-right: 0px;
}

.infoButton {
  color: $info !important;
  margin-right: auto !important;
  outline: none!important;
  box-shadow: none!important;
  border: none!important;
  &:hover, &:active {
    outline: none!important;
    box-shadow: none!important;
    color: $info !important;
  }
}
.hintText {
  color: $gray500;
  text-align: left;
  font: $small-paragraph;
  letter-spacing: 0px;
}

.error {
  border: 1px solid red;
}

.disabledInput {
  pointer-events: none;
  background: #e9ecef;
}

.row {
  padding-left: 20px;
}

.datePicker {
  > div {
    width: 100%;
    height: 36px;
    > div {
      > input {
        width: 100%;
        min-height: 36px;
      }
      > svg {
        right: 8px !important;
        height: 24px !important;
        width: 24px !important;
      }
    }
  }
  // [class*="rmdp-week"] {
  //   >[class*="rmdp-week-day"] {
  //     color: #323A46;
  //     font: $paragraph;
  //   }
  // }
}

@import '../../../../../../../styles/variables';

.modal {
  width: 600px;
}

.header {
  font: $large-label;
  color: $gray800;
  padding: 1.5rem;
}

.emailNotes {
  margin-top: 1rem;
}

.rowMarginBottom {
  margin-bottom: 1.5rem;
}

.cancelButton {
  margin-right: 0.75rem;
}

.inputDropdownCustom {
  padding: 0rem;
  margin: 0rem;
}

.inputDropdownInvalid {
  div {
    border-color: $danger;
  }
}

@import '../../../../../../../../../styles/variables';

.container {
  .wrapper {
    width: 100%;
    height: 100%;
    padding: 0.5rem;

    .defaultImageWrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: $gray200 0% 0% no-repeat padding-box;
      color: $gray500;
      position: relative;

      .uploadImage {
        font-weight: bold;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

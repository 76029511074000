@import '../../styles/index.scss';

.label {
  position: relative;
  display: block;
  margin-bottom: 8px;
  color: $gray800;
  font: $paragraph;
}

.strong {
  font-weight: bold;
}

.inline {
  display: inline-block;
}

.strikeThrough {
  text-decoration: line-through;
}

.requiredSymbol {
  color: $danger;
}

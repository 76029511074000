@import '../../../styles/variables';

.container {
  display: block;

  .leftSide {
    padding: 0px;
    border-right: 1px solid $gray300;
  }

  .rightSide {
    padding: 40px;

    .saveButtonRow {
      justify-content: flex-end;

      .saveButton {
        color: $white;
        background-color: $primary;
        padding: 0.5rem 2.5rem;
      }
    }
  }

  .sectionTitle {
    margin: 24px 0px;
    font: $large-label;
    color: $gray800;
  }
}

.listItemActive {
  color: $gray800 !important;
  font-weight: bold;
}

.listItem {
  display: flex;
  align-items: center;
  padding: 26px 16px;
  border-radius: 0px !important;
  border: none !important;
  border-bottom: 1px solid $gray300 !important;
  cursor: pointer;
  color: $gray500;

  .listItemIcon {
    padding-right: 1rem;

    &::before {
      font-size: 24px;
      max-width: 24px;
    }
  }
}

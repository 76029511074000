@import '../../../../../styles/variables';

.dateTimeGroupHeader {
  border-bottom: 1px solid $gray300 !important;
  padding-bottom: 20px;
  font-size: 14px !important;
  line-height: 16px !important;
  font-style: normal !important;
  font-weight: bold !important;
  color: #757575;
}

.subDateTimeGroupHeader {
  display: flex;
  padding-top: 20px;

  div:nth-child(1) {
    min-width: 90px;
  }

  div:nth-child(2) {
    min-width: 90px;
    margin: 0px 50px;
  }

  div:nth-child(3) {
    min-width: 50px;
  }
}

.titleCell {
  max-width: 86px;
}

.spinner {
  margin: auto;
}

@import '../../../../styles/variables';

.paragraph {
  margin-bottom: 1.5rem;
}

.email {
  color: $info;

  &:hover {
    cursor: pointer;
  }
}

.sendMailButton {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  color: $white;
  margin-bottom: 1rem;
}

.backButton {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: $white;
  color: $gray800;
}

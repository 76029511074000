@import '../../styles/index.scss';

.hfModalDialog {
  @media (min-width: 992px) {
    max-width: 808px;
  }
  .title {
    font: var(--font-style-normal) normal var(--font-weight-600) var(--font-size-18) / var(--line-spacing-24)
      var(--font-family-nunito);
    letter-spacing: var(--character-spacing-0);
    color: var(--gray800);
    font-size: 18px;
    text-align: left;
    font: $large-label;
    color: $gray800;
    opacity: 1;
  }

  [class*='modal-body'] {
    padding: 24px;
  }

  // Title text within header
  &:global(.modal-title) {
    margin-top: 0;
  }

  // Modal Positions
  &.modalTop {
    margin: 0 auto;
  }

  // Right Modal
  &.modalRight {
    position: absolute;
    right: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    margin: 0;
    background-color: $modal-content-bg;
    align-content: center;

    button:global(.close) {
      position: fixed;
      top: 20px;
      right: 20px;
      z-index: 1;
    }
  }

  // Left Modal
  &.modalLeft {
    position: absolute;
    left: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    margin: 0;
    background-color: $modal-content-bg;
    align-content: center;

    button:global(.close) {
      position: fixed;
      top: 20px;
      left: 20px;
      z-index: 1;
    }
  }

  &:global(.modal) {
    &:global(.show) {
      .modalRight {
        transform: translate(0, 0) !important;
      }
    }
  }

  // Bottom modal
  &.modalBottom {
    display: flex;
    flex-flow: column nowrap;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%;
    margin: 0 auto;
    align-content: center;
  }
}

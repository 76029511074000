@import '../../styles/variables';

.container {
  height: 100vh;
}

.wrapper {
  display: flex;
}

.leftSide {
  position: relative;
  width: 50vw;
  height: 100vh;
}

.rightSide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100vh;
  background-color: #f2f2f2;
}

.leftSideBgImage {
  width: 100%;
  height: 100vh;
  position: absolute;
  object-fit: cover;
}

.leftSideOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.leftSideLogoImage {
  width: 50%;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.rightSideContentWrapper {
  width: 350px;
  height: fit-content;
}

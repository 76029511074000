@import '../../../../../styles/variables';

.rowMarginBottom {
  margin-bottom: 40px;
}

.formCustom {
  width: 100%;
}

.saveButtonRow {
  justify-content: flex-end;

  .saveButton {
    color: $white;
    background-color: $primary;
    padding: 8px 40px;
    &:disabled {
      background-color: $primary;
      color: $white;
    }
    &:hover {
      color: $white;
      background-color: #f36700;
      border-color: #e66100;
    }
  }
}

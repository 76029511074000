@import '../../../../../../../styles/variables';

.container {
  padding: 0rem 0rem 1.5rem 0rem;
  margin: 0px;
}

.tableContainer {
  overflow-x: overlay;
  border-radius: 0.25rem;
  border: 1px solid #e2e2e2;
}

table {
  thead {
    background-color: $gray200;
  }

  td {
    font: $paragraph !important;
  }
}

.iconActions {
  &::before {
    font-size: 1.5rem;
  }
}

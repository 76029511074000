@import '../../../styles/variables';

.container {
  margin: 24px 0px;

  .sectionTitle {
    margin-bottom: 24px;

    color: $gray800;
    font-size: 18px;
  }
}

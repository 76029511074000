@import '../../styles/variables';

.table {
  position: relative;
  border-collapse: collapse;
  border-radius: 4px;
  overflow: visible;
  box-shadow: 0px 0px 0px 1px $geyser;
  border-radius: 4px;
  width: 100%;

  .thead {
    cursor: pointer;

    tr {
      border-bottom: 1px solid $geyser;

      th {
        padding: 20px;
        font-size: 14px;
        line-height: 16px;
        font-style: normal;
        font-weight: bold;
        color: #757575;
        white-space: nowrap;

        &:first-child {
          padding-left: 24px;
        }
      }
    }
  }

  .tbody {
    tr {
      border-bottom: 1px solid $geyser;

      &:last-child {
        border-bottom: none;
      }

      td {
        padding: 20px;
        font-size: 14px;
        line-height: 20px;
        font-style: normal;
        font-weight: normal;
        color: #1f1f1f;

        &:nth-child(7) {
          white-space: nowrap;
        }

        &:last-child {
          width: 50px;
          padding-left: 0;
          text-align: end;
        }

        &:first-child {
          padding-left: 24px;
          width: unset;
          text-align: unset;
        }

        &.subComponentContainer {
          padding: 0;
        }
      }

      &.isExpanded {
        border-left: 2px solid gray;

        & + tr {
          border-left: 2px solid gray;
        }
      }
    }

    &.isStriped {
      > tr:nth-child(even) {
        background-color: $gray100;
      }
      > tr:nth-child(odd) {
        border-bottom: unset;
      }
      &.isExpanded {
        > tr:nth-child(even) {
          background-color: unset;
        }
        > tr:nth-child(4n + 3) {
          background-color: $gray100;
          & + tr {
            transform: translateY(-1px);
            background-color: $gray100
          }
        }
      }
    }
  }
}

.loadingBar {
  position: absolute;
  z-index: 5;
}

.icon {
  width: 14px;
}

.active {
  background-color: #d1e7dd80 !important;
  border: 1px solid #e6e5e5ad;
}

.status {
  text-align: center;
  padding: 4px 8px;
  border-radius: 10px;
}

.statusActivation {
  color: #004085;
  background-color: #e0efff;
}

.statusReview {
  color: #856404;
  background-color: #fff8e1;
}

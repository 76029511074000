@import '../../../../../../../styles/variables';

.modal {
  h4,
  h5 {
    margin-top: 8px;
    margin-bottom: 0;
    text-align: left;
    font: $heading45;
    color: $gray800;
  }

  span {
    font-size: 32px;
  }
}

.modalFooter {
  display: flex;
  justify-content: center;

  margin-top: 24px;

  button {
    background-color: $primary;
    border-color: $primary;
  }
}

.cardWrapper {
  margin: 12px;
  padding: 16px 36px;
  background: $white-background;
  border: 2px solid $primary;
  border-radius: 4px;
  opacity: 1;
  text-align: center;
}

.image {
  width: 64px;
  height: 64px;
}

.cardLabel {
  font-weight: bold;
  font-size: 24px;
  white-space: nowrap;
  text-align: center !important;
}

.noPadding {
  padding-left: 0px;
  padding-right: 0px;
}

.infoButton {
  color: $info !important;
  margin-right: auto !important;
  outline: none!important;
  box-shadow: none!important;
  border: none!important;
  &:hover, &:active {
    outline: none!important;
    box-shadow: none!important;
    color: $info !important;
  }
}
.padding {
  margin-left: 5px;
}

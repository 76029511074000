@import '../../../../../../../../../styles/index.scss';

$datepicker__background-color: $white !default;
$datepicker__border-color: $dropdown-border-color !default;
$datepicker__highlighted-color: $primary !default;
$datepicker__muted-color: $text-muted !default;
$datepicker__selected-color: $primary !default;
$datepicker__text-color: $text-muted !default;
$datepicker__header-color: $text-muted !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%) !default;

$datepicker__border-radius: 0.3rem !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: $font-size-base !default;
$datepicker__font-family: $font-family-base !default;

$datepicker__item-size: 1.5rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-size: 0.45rem !default;
$datepicker__triangle-size: 8px !default;

.error {
  border: 1px solid red;
}

.inputStyle {
  input {
    background: white;
  }

  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none;
}

.append {
  span {
    color: $gray800 !important;
    border-color: $geyser !important;
    border-left: none !important;
    background-color: unset !important;
  }
}

[class*='form-control'] {
  :focus {
    background-color: red;
  }
}

.hfDateTimePicker {
  %triangle-arrow {
    margin-left: -$datepicker__triangle-size;
    position: absolute;

    &,
    &::before {
      box-sizing: content-box;
      position: absolute;
      border: $datepicker__triangle-size solid transparent;

      height: 0;
      width: 1px;
    }

    &::before {
      content: '';
      z-index: -1;
      border-width: $datepicker__triangle-size;

      left: -$datepicker__triangle-size;
      border-bottom-color: $datepicker__border-color;
    }
  }

  %triangle-arrow-up {
    @extend %triangle-arrow;

    top: 0;
    margin-top: -$datepicker__triangle-size;

    &,
    &::before {
      border-top: none;
      border-bottom-color: $datepicker__background-color;
    }

    &::before {
      top: -1px;
      border-bottom-color: $datepicker__border-color;
    }
  }

  %triangle-arrow-down {
    @extend %triangle-arrow;

    bottom: 0;
    margin-bottom: -$datepicker__triangle-size;

    &,
    &::before {
      border-bottom: none;
      border-top-color: #fff;
    }

    &::before {
      bottom: -1px;
      border-top-color: $datepicker__border-color;
    }
  }

  & :global(.react-datepicker-wrapper) {
    display: block;
    flex: 1 !important;
  }

  & :global(.react-datepicker) {
    font-family: $datepicker__font-family;
    font-size: $datepicker__font-size;
    background-color: #fff;
    color: $datepicker__text-color;
    border: 1px solid $datepicker__border-color;
    border-radius: $datepicker__border-radius;
    display: inline-block;
    position: relative;
  }

  & :global(.react-datepicker--time-only) {
    :global(.react-datepicker__triangle) {
      left: 35px;
    }

    :global(.react-datepicker__time-container) {
      border-left: 0;
    }

    :global(.react-datepicker__time) {
      border-radius: 0.3rem;
    }

    :global(.react-datepicker__time-box) {
      border-radius: 0.3rem;
    }
  }

  & :global(.react-datepicker__triangle) {
    position: absolute;
    left: 50px;
  }

  & :global(.react-datepicker-popper) {
    z-index: 1;

    &[data-placement^='bottom'] {
      margin-top: $datepicker__triangle-size + 2px;

      :global(.react-datepicker__triangle) {
        @extend %triangle-arrow-up;
      }
    }

    &[data-placement^='top'] {
      margin-bottom: $datepicker__triangle-size + 2px;

      :global(.react-datepicker__triangle) {
        @extend %triangle-arrow-down;
      }
    }

    &[data-placement^='right'] {
      margin-left: $datepicker__triangle-size;

      :global(.react-datepicker__triangle) {
        left: auto;
        right: 42px;
      }
    }

    &[data-placement^='left'] {
      margin-right: $datepicker__triangle-size;

      :global(.react-datepicker__triangle) {
        left: 42px;
        right: auto;
      }
    }
  }

  & :global(.react-datepicker__header) {
    text-align: center;
    background-color: $datepicker__background-color;
    border-bottom: 1px solid $datepicker__border-color;
    border-top-left-radius: $datepicker__border-radius;
    border-top-right-radius: $datepicker__border-radius;
    padding-top: 8px;
    position: relative;

    &--time {
      padding-bottom: 8px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  & :global(.react-datepicker__year-dropdown-container--select),
  & :global(.react-datepicker__month-dropdown-container--select),
  & :global(.react-datepicker__month-year-dropdown-container--select),
  & :global(.react-datepicker__year-dropdown-container--scroll),
  & :global(.react-datepicker__month-dropdown-container--scroll),
  & :global(.react-datepicker__month-year-dropdown-container--scroll) {
    display: inline-block;
    margin: 0 2px;
  }

  & :global(.react-datepicker__current-month),
  & :global(.react-datepicker-time__header),
  & :global(.react-datepicker-year-header) {
    margin-top: 0;
    color: $datepicker__header-color;
    font-weight: bold;
    font-size: $datepicker__font-size;
  }

  & :global(.react-datepicker-time__header) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & :global(.react-datepicker__navigation) {
    background: none;
    line-height: $datepicker__item-size;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: 0;
    padding: 0;
    border: $datepicker__navigation-size solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;

    &--previous {
      left: 10px;
      border-right-color: $datepicker__muted-color;

      &:hover {
        border-right-color: darken($datepicker__muted-color, 10%);
      }

      &--disabled,
      &--disabled:hover {
        border-right-color: $datepicker__navigation-disabled-color;
        cursor: default;
      }
    }

    &--next {
      right: 10px;
      border-left-color: $datepicker__muted-color;

      &--with-time:not(&--with-today-button) {
        right: 80px;
      }

      &:hover {
        border-left-color: darken($datepicker__muted-color, 10%);
      }

      &--disabled,
      &--disabled:hover {
        border-left-color: $datepicker__navigation-disabled-color;
        cursor: default;
      }
    }

    &--years {
      position: relative;
      top: 0;
      display: block;
      margin-left: auto;
      margin-right: auto;

      &-previous {
        top: 4px;
        border-top-color: $datepicker__muted-color;

        &:hover {
          border-top-color: darken($datepicker__muted-color, 10%);
        }
      }

      &-upcoming {
        top: -4px;
        border-bottom-color: $datepicker__muted-color;

        &:hover {
          border-bottom-color: darken($datepicker__muted-color, 10%);
        }
      }
    }
  }

  & :global(.react-datepicker__month-container) {
    float: left;
  }

  & :global(.react-datepicker__month) {
    margin: $datepicker__margin;
    text-align: center;

    :global(.react-datepicker__month-text) {
      display: inline-block;
      width: 4rem;
      margin: 2px;
    }
  }

  & :global(.react-datepicker__input-time-container) {
    clear: both;
    width: 100%;
    float: left;
    margin: 5px 0 10px 15px;
    text-align: left;

    :global(.react-datepicker-time__caption) {
      display: inline-block;
    }

    :global(.react-datepicker-time__input-container) {
      display: inline-block;

      :global(.react-datepicker-time__input) {
        display: inline-block;
        margin-left: 10px;

        input {
          width: 85px;
        }

        input[type='time']::-webkit-inner-spin-button,
        input[type='time']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type='time'] {
          -moz-appearance: textfield;
        }
      }

      :global(.react-datepicker-time__delimiter) {
        margin-left: 5px;
        display: inline-block;
      }
    }
  }

  & :global(.react-datepicker__time-container) {
    float: right;
    border-left: 1px solid $datepicker__border-color;
    width: 100px;

    &--with-today-button {
      display: inline;
      border: 1px solid #aeaeae;
      border-radius: 0.3rem;
      position: absolute;
      right: -72px;
      top: 0;
    }

    :global(.react-datepicker__time) {
      position: relative;
      background: white;

      :global(.react-datepicker__time-box) {
        width: 100px;
        overflow-x: hidden;
        margin: 0 auto;
        text-align: center;

        ul:global(.react-datepicker__time-list) {
          list-style: none;
          margin: 0;
          height: calc(195px + (#{$datepicker__item-size} / 2));
          overflow-y: scroll;
          padding-right: 0px;
          padding-left: 0px;
          width: 100%;
          box-sizing: content-box;

          li:global(.react-datepicker__time-list-item) {
            height: 30px;
            padding: 5px 10px;

            &:hover {
              cursor: pointer;
              background-color: $datepicker__background-color;
            }

            &--selected {
              background-color: $datepicker__selected-color;
              color: white;
              font-weight: bold;

              &:hover {
                background-color: $datepicker__selected-color;
              }
            }

            &--disabled {
              color: $datepicker__muted-color;

              &:hover {
                cursor: default;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }

  & :global(.react-datepicker__week-number) {
    color: $datepicker__muted-color;
    display: inline-block;
    width: $datepicker__item-size;
    line-height: $datepicker__item-size;
    text-align: center;
    margin: $datepicker__day-margin;

    &:global(.react-datepicker__week-number--clickable) {
      cursor: pointer;

      &:hover {
        border-radius: $datepicker__border-radius;
        background-color: $datepicker__background-color;
      }
    }
  }

  & :global(.react-datepicker__day-names),
  & :global(.react-datepicker__week) {
    white-space: nowrap;
  }

  & :global(.react-datepicker__day-name),
  & :global(.react-datepicker__day),
  & :global(.react-datepicker__time-name) {
    color: $datepicker__text-color;
    display: inline-block;
    width: $datepicker__item-size;
    line-height: $datepicker__item-size;
    text-align: center;
    margin: $datepicker__day-margin;
  }

  & :global(.react-datepicker__month) {
    &--selected,
    &--in-selecting-range,
    &--in-range {
      border-radius: $datepicker__border-radius;
      background-color: $datepicker__selected-color;
      color: #fff;

      &:hover {
        background-color: darken($datepicker__selected-color, 5%);
      }
    }

    &--disabled {
      color: $datepicker__muted-color;
      pointer-events: none;

      &:hover {
        cursor: default;
        background-color: transparent;
      }
    }
  }

  & :global(.react-datepicker__day),
  & :global(.react-datepicker__month-text) {
    cursor: pointer;

    &:hover {
      border-radius: $datepicker__border-radius;
      background-color: $datepicker__background-color;
    }

    &--today {
      font-weight: bold;
    }

    &--highlighted {
      border-radius: $datepicker__border-radius;
      background-color: $datepicker__highlighted-color;
      color: #fff;

      &:hover {
        background-color: darken($datepicker__highlighted-color, 5%);
      }

      &-custom-1 {
        color: magenta;
      }

      &-custom-2 {
        color: green;
      }
    }

    &--selected,
    &--in-selecting-range,
    &--in-range {
      border-radius: $datepicker__border-radius;
      background-color: $datepicker__selected-color;
      color: #fff;

      &:hover {
        background-color: darken($datepicker__selected-color, 5%);
      }
    }

    &--keyboard-selected {
      border-radius: $datepicker__border-radius;
      background-color: lighten($datepicker__selected-color, 10%);
      color: #fff;

      &:hover {
        background-color: darken($datepicker__selected-color, 5%);
      }
    }

    &--in-selecting-range:not(&--in-range) {
      background-color: rgba($datepicker__selected-color, 0.5);
    }

    &--in-range:not(&--in-selecting-range) {
      :global(.react-datepicker__month--selecting-range) & {
        background-color: $datepicker__background-color;
        color: $datepicker__text-color;
      }
    }

    &--disabled {
      cursor: default;
      color: $datepicker__muted-color;

      &:hover {
        background-color: transparent;
      }
    }
  }

  & :global(.react-datepicker__month-text) {
    &:global(.react-datepicker__month--selected),
    &:global(.react-datepicker__month--in-range) {
      &:hover {
        background-color: $datepicker__selected-color;
      }
    }

    &:hover {
      background-color: $datepicker__background-color;
    }
  }

  & :global(.react-datepicker__input-container) {
    position: relative;
    display: block;
  }

  & :global(.react-datepicker__year-read-view),
  & :global(.react-datepicker__month-read-view),
  & :global(.react-datepicker__month-year-read-view) {
    border: 1px solid transparent;
    border-radius: $datepicker__border-radius;

    &:hover {
      cursor: pointer;

      :global(.react-datepicker__year-read-view--down-arrow),
      :global(.react-datepicker__month-read-view--down-arrow) {
        border-top-color: darken($datepicker__muted-color, 10%);
      }
    }

    &--down-arrow {
      @extend %triangle-arrow-down;
      border-top-color: $datepicker__muted-color;
      float: right;
      margin-left: 20px;
      top: 8px;
      position: relative;
      border-width: $datepicker__navigation-size;
    }
  }

  & :global(.react-datepicker__year-dropdown),
  & :global(.react-datepicker__month-dropdown),
  & :global(.react-datepicker__month-year-dropdown) {
    background-color: $datepicker__background-color;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: $datepicker__border-radius;
    border: 1px solid $datepicker__border-color;

    &:hover {
      cursor: pointer;
    }

    &--scrollable {
      height: 150px;
      overflow-y: scroll;
    }
  }

  & :global(.react-datepicker__year-option),
  & :global(.react-datepicker__month-option),
  & :global(.react-datepicker__month-year-option) {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:first-of-type {
      border-top-left-radius: $datepicker__border-radius;
      border-top-right-radius: $datepicker__border-radius;
    }

    &:last-of-type {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border-bottom-left-radius: $datepicker__border-radius;
      border-bottom-right-radius: $datepicker__border-radius;
    }

    &:hover {
      background-color: $datepicker__muted-color;

      :global(.react-datepicker__navigation--years-upcoming) {
        border-bottom-color: darken($datepicker__muted-color, 10%);
      }

      :global(.react-datepicker__navigation--years-previous) {
        border-top-color: darken($datepicker__muted-color, 10%);
      }
    }

    &--selected {
      position: absolute;
      left: 15px;
    }
  }

  & :global(.react-datepicker__close-icon) {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    padding: 0;
    vertical-align: middle;
    position: absolute;
    height: 16px;
    width: 16px;
    top: 25%;
    right: 7px;

    &::after {
      background-color: $datepicker__selected-color;
      border-radius: 50%;
      bottom: 0;
      box-sizing: border-box;
      color: #fff;
      content: '\00d7';
      cursor: pointer;
      font-size: 12px;
      height: 16px;
      width: 16px;
      line-height: 1;
      margin: -8px auto 0;
      padding: 2px;
      position: absolute;
      right: 0px;
      text-align: center;
    }
  }

  & :global(.react-datepicker__today-button) {
    background: $datepicker__background-color;
    border-top: 1px solid $datepicker__border-color;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
  }

  & :global(.react-datepicker__portal) {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;

    :global(.react-datepicker__day-name),
    :global(.react-datepicker__day),
    :global(.react-datepicker__time-name) {
      width: 3rem;
      line-height: 3rem;
    }

    // Resize for small screens
    @media (max-width: 400px), (max-height: 550px) {
      :global(.react-datepicker__day-name),
      :global(.react-datepicker__day),
      :global(.react-datepicker__time-name) {
        width: 2rem;
        line-height: 2rem;
      }
    }

    :global(.react-datepicker__current-month),
    :global(.react-datepicker-time__header) {
      font-size: $datepicker__font-size * 1.8;
    }

    :global(.react-datepicker__navigation) {
      border: 1.8 * $datepicker__navigation-size solid transparent;
    }

    :global(.react-datepicker__navigation--previous) {
      border-right-color: $datepicker__muted-color;

      &:hover {
        border-right-color: darken($datepicker__muted-color, 10%);
      }

      &--disabled,
      &--disabled:hover {
        border-right-color: $datepicker__navigation-disabled-color;
        cursor: default;
      }
    }

    :global(.react-datepicker__navigation--next) {
      border-left-color: $datepicker__muted-color;

      &:hover {
        border-left-color: darken($datepicker__muted-color, 10%);
      }

      &--disabled,
      &--disabled:hover {
        border-left-color: $datepicker__navigation-disabled-color;
        cursor: default;
      }
    }
  }

  // //
  // // flatpickr.scss
  // //
  // @import '~flatpickr/dist/flatpickr.css';

  // .flatpickr-calendar {
  //   overflow: hidden;
  //   background-color: $card-bg;
  //   box-shadow: none;
  //   border: 2px solid $gray300;

  //   .flatpickr-time {
  //     border: none !important;

  //     input,
  //     .flatpickr-am-pm {
  //       color: $gray600;

  //       &:focus,
  //       &:hover {
  //         background: $gray300;
  //       }
  //     }
  //   }
  // }

  // .flatpickr-day {
  //   color: $gray600;

  //   &.selected,
  //   &.startRange,
  //   &.endRange,
  //   &.selected.inRange,
  //   &.startRange.inRange,
  //   &.endRange.inRange,
  //   &.selected:focus,
  //   &.startRange:focus,
  //   &.endRange:focus,
  //   &.selected:hover,
  //   &.startRange:hover,
  //   &.endRange:hover,
  //   &.selected.prevMonthDay,
  //   &.startRange.prevMonthDay,
  //   &.endRange.prevMonthDay,
  //   &.selected.nextMonthDay,
  //   &.startRange.nextMonthDay,
  //   &.endRange.nextMonthDay {
  //     background: $primary !important;
  //     border-color: $primary !important;
  //   }

  //   &.selected.startRange+.endRange:not(:nth-child(7n+1)),
  //   &.startRange.startRange+.endRange:not(:nth-child(7n+1)),
  //   &.endRange.startRange+.endRange:not(:nth-child(7n+1)) {
  //     box-shadow: -10px 0 0 $primary !important;
  //   }

  //   &.today {
  //     border-color: $gray300 !important;
  //   }

  //   &.inRange {
  //     background: darken($bg-leftbar-light, 4%) !important;
  //     border-color: darken($bg-leftbar-light, 4%) !important;
  //     box-shadow: -10px 0 0 darken($bg-leftbar-light, 4%) !important;

  //     &.nextMonthDay {
  //       background: darken($bg-leftbar-light, 4%) !important;
  //       border-color: darken($bg-leftbar-light, 4%) !important;
  //     }
  //   }

  //   &.flatpickr-disabled,
  //   &.flatpickr-disabled:hover,
  //   &.prevMonthDay,
  //   &.nextMonthDay,
  //   &.notAllowed,
  //   &.notAllowed.prevMonthDay,
  //   &.notAllowed.nextMonthDay {
  //     color: rgba($gray600, 0.4);
  //   }

  //   &.prevMonthDay.inRange,
  //   &.nextMonthDay.inRange,
  //   &.today.inRange,
  //   &.today:hover,
  //   &.today:focus,
  //   &.prevMonthDay.today.inRange,
  //   &.nextMonthDay.today.inRange,
  //   &:hover,
  //   &.prevMonthDay:hover,
  //   &.nextMonthDay:hover,
  //   &:focus,
  //   &.prevMonthDay:focus,
  //   &.nextMonthDay:focus {
  //     background: $gray300 !important;
  //     border-color: $gray300 !important;
  //     color: $gray600;
  //   }
  // }

  // .flatpickr-time {

  //   input:hover,
  //   .flatpickr-am-pm:hover,
  //   input:focus,
  //   .flatpickr-am-pm:focus {
  //     background: $gray100 !important;
  //   }
  // }

  // .flatpickr-months {

  //   // background: $primary;
  //   .flatpickr-month {
  //     height: 48px !important;
  //   }

  //   .flatpickr-prev-month,
  //   .flatpickr-next-month {
  //     fill: $body-color !important;
  //   }
  // }

  // .flatpickr-current-month {
  //   color: $body-color;
  //   font-size: 1rem !important;
  //   padding: 8px 0 0 0 !important;

  //   .flatpickr-monthDropdown-months {
  //     .flatpickr-monthDropdown-month {
  //       color: $gray700;
  //     }
  //   }
  // }

  // .flatpickr-weekdays {
  //   background: $primary !important;
  // }

  // .flatpickr-weekday {
  //   color: rgba($white, 0.6) !important;
  // }

  // .calendar-widget {
  //   .flatpickr-calendar {
  //     box-shadow: none;
  //     border: $border-width solid $border-color !important;
  //   }

  //   .flatpickr-input {
  //     display: none;
  //   }
  // }
}

.page__overlay__wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1009;
  background: rgba(0, 0, 0, 0.5);
}

.center__frame {
  margin: 0, auto;
  text-align: center;
  position: fixed;
  top: 50vh;
  left: 50%;
  transform: translateY(-50%);

  .spinners {
    width: 100px;
    height: 100px;
    border-width: 0.5rem;
  }
}

@import '../../../../../../../styles/variables';

.modal {
  h4 {
    margin-top: 8px;
    margin-bottom: 0;
    text-align: left;
    font: $heading4;
    color: $gray800;
  }
  .cardWrapper {
    margin: 12px;
    padding: 30px;
    background: $white-background;
    border: 2px solid $primary;
    border-radius: 4px;
    .iconStyle {
      &::before {
        font-size: 40px;
        color: $success;
      }
    }
  }
}

@import '../../../styles/variables';

.title {
  font-weight: bold;
  font-size: 40px;
  color: $gray800;
  padding-bottom: 2.5rem;
}

.label {
  font-size: 16px;
  color: $gray800;
  margin-bottom: 0.5rem;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.selectRoleArea {
  margin-bottom: 1.5rem;
}

.continueButton {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  color: $white;
}

.disableContinueButton {
  background-color: $gray300;
}

.passwordInput {
  border-right: none;
  border-radius: 4px 0px 0px 4px !important;
}

.inputAppendIcon {
  background-color: $white;
  border-left: none;
  border-radius: 0px 4px 4px 0px !important;

  &:hover {
    cursor: pointer;
  }
}

.rememberForgetGroup {
  display: flex;
  justify-content: space-between;
}

.forgotPassword {
  color: $info;

  &:hover {
    cursor: pointer;
  }
}

@import '../../../../styles/variables';

.expanded {
  [class*='tableRows'] {
    color: black !important;
  }

  [class*='iconStyle'] {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    transition: 0.5s;
    font-size: 16px;
    padding-right: 8px;
    margin-top: 3px;
    color: black !important;
  }
}

@import '../../../../../styles/variables';

.rowMarginBottom {
  margin-bottom: 2.5rem;
}

.rowPaddingBottom {
  padding-bottom: 1.5em;
}

.inputLeftCol {
  padding: 0rem 0.75rem 0rem 0rem;
}

.inputMiddleCol {
  padding: 0rem 0.75rem;
}

.inputRightCol {
  padding: 0rem 0rem 0rem 0.75rem;
}

.inputCustom {
  padding: 0.5rem 0.75rem;
  color: $gray800 !important;
  font: $paragraph !important;
  border: 1px solid $gray300;
  border-radius: 0.25rem;
}

.customCombobox {
  padding: 0rem 0.75rem;
  border: 1px solid $gray300 !important;
  border-radius: 0.25rem;

  select {
    padding: 0.5rem 0rem;
  }

  .optionCustom {
    padding: 0rem 0.75rem;
  }
}

@import '../../../../../../../styles/variables';

.container {
  display: flex;
  flex-direction: row;
  padding-left: 4px;
  padding-top: 18px;
  padding-bottom: 18px;
  font-family: Nunito;
  font-size: 16px;
  background: $white-background;
  border: 1px solid #eeeeee;
  font: $paragraph;
  color: $gray700;

  .column {
    min-width: 15%;
    &:first-child {
      padding-left: 8px;
      width: max-content;
      min-width: unset;
    }
    &:nth-child(2) {
      min-width: 12%;
    }
    &:nth-child(3) {
      min-width: 18%;
    }
    &:nth-child(4) {
      width: 180px;
      min-width: unset;
      text-align: start;
    }

    &:not(:first-child):not(:last-child) {
      border-top: 0px;
    }

    &:last-child {
      min-width: unset;
      margin-left: auto;
      padding-right: 24px;
      i::before {
        font-size: 24px;
        color: #999999;
        cursor: pointer;
      }
    }

    .iconStyle {
      padding-right: 8px;
      color: $primary;
      width: 32px;
      height: 24px;
    }
  }

  .timeStyle {
    width: 173px;
    min-width: unset;
    text-align: end;
    white-space: nowrap;
    color: $primary;
    > div {
      width: fit-content;
      display: inline-block;
    }
  }
  &.inForm {
    margin-bottom: 16px;
  }
}

@import '../../../../../../../styles/variables';

.container {
  color: $gray800;
}

.rowMarginBottom {
  margin-bottom: 2.5rem;
}

.col {
  padding: 0rem;
  display: flex;
  justify-content: space-between;
}

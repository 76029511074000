@import '../../../../../../../../../styles/variables';
.infoButton {
  color: $info !important;
  margin-right: auto !important;
  outline: none!important;
  box-shadow: none!important;
  border: none!important;
  &:hover, &:active {
    outline: none!important;
    box-shadow: none!important;
    color: $info !important;
  }
}
.submitButton {
  color: white !important;
}

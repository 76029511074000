@import '../../../../../../../../../styles/variables';

.container {
  .wrapper {
    width: 100%;
    height: 100%;
    position: relative;

    input {
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
  }
}

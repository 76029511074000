@import '../../../../../styles/variables';

.rowMarginBottom {
  margin-bottom: 2.5rem;
}

.formCustom {
  width: 100%;
}

.passwordHelper {
  color: $gray800;
  font: $paragraph;
}

.requirementCustom {
  align-items: center;
  margin-top: 0.5rem;

  i {
    margin-right: 0.5rem;
    color: $primary;
  }
}

.inputCustom {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0;
}

.addonInputCustom {
  cursor: pointer;
  background-color: unset;
  border-radius: 0rem 0.25rem 0.25rem 0rem !important;
}

.saveButtonRow {
  justify-content: flex-end;

  .saveButton {
    color: $white;
    background-color: $primary;
    padding: 0.5rem 2.5rem;
  }
}

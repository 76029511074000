@import '../../../../../../../styles/variables';

.rowCustom {
  align-items: center;
  margin-bottom: 1.5rem;
  flex-direction: column;
}

.emptyIcon {
  height: 3.5rem;
  width: 4.5rem;
}

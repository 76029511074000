.container {
  color: #1f1f1f;
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &.small {
    padding-left: 24px;
    margin-bottom: 24px;
  }

  &.hidden {
    display: none;
  }
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #1f1f1f;
  &.small {
    height: 16px;
    width: 16px;
  }
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  border: 1px solid #ff7b1a;
  background-color: white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 7px;
  left: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.checked {
  color: #ff7b1a;
}

.disabled {
  color: lightgray;
  cursor: not-allowed;
}

.small {
  .checkmark {
    height: 16px;
    width: 16px;
    top: 3px;
    &::after {
      left: 4px;
      top: 4px;
      width: 6px;
      height: 6px;
      background: #ff7b1a;
    }
  }
}

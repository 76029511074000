@import '../../../../../styles/variables';

.noSpecial {
  height: 54px;
  background: white;
  line-height: 54px;
  color: #999999;
  padding-left: 15px;
  font-family: Nunito;
  font-style: italic;
  font-size: 16px;
}

.specialWrapper {
  padding-top: 0;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  &.noPading {
    padding: 0 12px;
  }
}

.title {
  span {
    font: $small-label-bold;
    color: $gray800;
    padding-left: 12px;
  }
  padding-bottom: 16px;
  padding-left: 0;
  padding-right: 0;
}
.subtext {
  font: $large-label;
  letter-spacing: 0px;
  color: $gray800;
}
.link {
  color: $info;
  margin-left: 4px;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    filter: contrast(130%);
  }
}
.addMore {
  > span {
    font: $paragraph;
    color: $info;
    cursor: pointer;
    > i {
      color: $info;
      margin-right: 4px;
      ::before {
        font-size: 16px;
      }
    }
  }
}

@import '../../../../../../../../../styles/variables';

.addButton {
  width: 32px;
  height: 32px;
  color: white;
  border-radius: 4px;
  background-color: $primary;
  border: none;
  font-size: 21px;
}

.disabledButton {
  background-color: $gray300;
}

.container {
  color: #1f1f1f;
  display: block;
  position: relative;
  cursor: pointer;
  padding-left: 40px;
  margin-bottom: 24px;
  user-select: none;

  &.small {
    padding-left: 30px;
    margin-bottom: 16px;
  }

  &.extranet {
    padding-left: 28px;
    margin-bottom: 0px;
  }
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #cbcbcb;
}

.squared {
  border-radius: 4px;
}

.circled {
  border-radius: 25px;
}

.disabled {
  background-color: #ccc !important;
}

/* On mouse-over, add a grey background color */
.input ~ .checkmark {
  background-color: white;
  border: 1px solid #1f1f1f;
}

/* When the checkbox is checked, add a blue background */
.input:checked ~ .checkmark {
  border: 1px solid #ff7b1a;
  background-color: white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #ff7b1a;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.small {
  .checkmark {
    height: 16px;
    width: 16px;
    top: 3px;

    &::after {
      left: 5px;
      top: 2px;
      width: 4px;
      height: 8px;
      border: solid #ff7b1a;
      border-width: 0 1px 1px 0;
    }

    &.websiteTheme {
      top: 1px;
    }
  }
}

.field {
  margin-top: 44px;
}

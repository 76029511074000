@import '../../../styles/variables';

.sectionTitle {
  margin-top: 24px;
  margin-bottom: 24px;
  font: $large-label;
  color: $gray800;
}

.whiteBackground {
  padding: 16px;
  background-color: white;

  [class*='tabs_layout'] {
    margin-bottom: 24px !important;
  }

  thead {
    background: #eeeeee 0% 0% no-repeat padding-box !important;
    border-radius: 4px 4px 0px 0px;
    font: $small-label-semi-bold !important;
    tr th {
      color: $gray800 !important;
    }
  }
}

.iconStyle {
  padding-right: 8px;
  color: $primary;
  font-family: Inter;
}

.helperIcon {
  color: $info;
  transform: translateX(4px);
}

.img {
  width: 24px;
  height: 24px;
  transform: translateY(-4px);
  filter: saturate(2);
}

.tbody > tr > td > div {
  *:not([class*='iconStyle']) {
    font: $paragraph;
    color: $gray700;
  }
}

.label {
  margin-top: 24px;
  margin-bottom: 24px;
  white-space: nowrap;
  font: $paragraph;
  color: $gray600;
}

.dropdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  [class*='select__control'] {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-color: rgb(204, 204, 204);
    box-shadow: unset;
    &:hover,
    &:active,
    &:focus {
      border-color: rgb(204, 204, 204);
      box-shadow: unset;
    }
  }
  padding: 0;
  > div {
    padding: 0;
  }
}

.input {
  padding: 14px 15px 0 0;
  > input {
    padding: 8.5px;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-left: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}
.filterContainer {
  max-height: 72px;
}
.cardWrapper {
  height: 282px;
  margin-left: 0px;
  margin-right: -24px;

  .cardContainer {
    height: 100%;
    border-radius: 4px;
    background: $white-background;
    box-shadow: 2px 2px 8px #0000000d;
    padding: 24px;
    width: calc(20% - 25px);
    margin-right: 24px;
    margin-bottom: 16px;
    &:nth-child(5) {
      margin-right: 0;
    }
    display: inline-block;
    .cardTitle {
      min-height: 48px;
      max-width: 70%;
      color: #333333;
      white-space: normal;
      display: inline-block;
      text-transform: uppercase;
      font: normal normal bold 16px/24px Nunito;
      @media only screen and (max-width: 1200px) {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .cardBody {
      width: 100%;
      [class*='col-md-9'] {
        padding: 0;
      }
    }
    transition: all ease-in-out 250ms;
    &.hasChart {
      transition: all ease-in-out 250ms;
      width: calc(40% - 25px);
    }
  }
  .cornerIcon {
    float: right;
    color: #999999;
    cursor: pointer;
    &::before {
      font-size: 24px;
    }
  }
}

.arrow {
  bottom: 1px !important;
}
.inner {
  font: $small-paragraph;
  letter-spacing: 0px;
  color: $white;
  transform: translateX(-10px);
}

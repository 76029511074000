@import '../../../../../../../../../styles/variables';

.container {
  display: inline-table;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 18px 16px;
}

.wrapper {
  display: table-cell;
}

.content {
  display: inline-block;
  border-radius: 4px;
  padding: 8px;

  div:nth-child(1) {
    font: normal normal 600 18px/24px Nunito;
  }

  div:nth-child(2) {
    font: normal normal normal 16px/24px Nunito;
  }
}

.whiteFontColor {
  background: #000000b3 0% 0% no-repeat padding-box;
}

.blackFontColor {
  background: #ffffffe6 0% 0% no-repeat padding-box;
}

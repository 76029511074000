@import '../../../../../../../styles/variables';

.textWrapper {
  margin-top: 8px;
  margin-right: 24px;
  .label {
    text-align: left;
    font: $paragraph;
    color: $gray800;
  }

  .value {
    text-align: left;
    font: $heading6;
    color: $gray800;
  }
  &.spaceTop {
    margin-top: 18px;
  }
  &.inline {
    display: inline-block;
  }
}

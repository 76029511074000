.scrollAble {
  overflow: auto;
  border-radius: 4px;
  border: 1px solid #eeeeee;

  [class*='subComponentContainer'] {
    > div {
      margin-right: 0;
      margin-left: 0;
      > div {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    border-radius: 4px;
    background: #aaaaaa;
  }
}

@import '../../../../../../../styles/variables';

.cellWrapper {
  display: flex;
}

.icon {
  &:hover {
    cursor: pointer;
  }

  &::before {
    color: $gray500;
    font-size: 1.5rem;
  }
}

.icon:nth-child(1) {
  margin-right: 8px;
}

.activate {
  &::before {
    color: $success;
  }
}

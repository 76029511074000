@import '../../../../../../../styles/variables';

.cellWrapper {
  hr {
    margin: 8px 0px;
  }
}

.line {
  display: flex;

  div:nth-child(1) {
    min-width: 90px;
  }

  > div:nth-child(2) {
    min-width: 90px;
    margin: 0px 50px;
  }

  div:nth-child(3) {
    min-width: 50px;
  }
}

.date {
  max-width: 89px;
}

.value {
  color: $gray700;
  font: normal normal bold 16px/24px Nunito;
  margin-right: 4px;
}

.rate {
  font: normal normal bold 12px/20px Nunito;
}

.increment {
  color: $success;
}

.decrement {
  color: $danger;
}

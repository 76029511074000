@import '../../styles/variables';

.container {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #000000;

  .selectCustom {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    margin-bottom: auto;
  }
}

.message {
  padding: 0.5rem;
}

.invalid {
  color: $danger !important;
  border-color: $danger !important;
}

.statusCell {
  padding: 4px 12px;
  border-radius: 24px;
  text-align: center;
  display: inline-flex;
  white-space: nowrap;
}

.Dine-in,
.green {
  border: 1px solid #c3e6cb;
  background-color: #e5f4e9;
  color: #155724;
}

.Takeout {
  border: 1px solid #ffeeba;
  background-color: #fff8e1;
  color: #856404;
}

.blue {
  border: 1px solid #b8d9f7;
  background-color: #d7eaf8;
  color: #004085;
}

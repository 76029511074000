@import '../../../../../../../../../styles/variables';

.date {
  max-width: 89px;
}

.value {
  color: $gray700;
  font: normal normal bold 16px/24px Nunito;
  margin-right: 4px;
}

.rate {
  font: normal normal bold 12px/20px Nunito;
}

.increment {
  color: $success;
}

.decrement {
  color: $danger;
}

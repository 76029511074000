@import '../../../../../styles/variables';

.container {
  background-color: $white;
  padding: 24px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  border-radius: 4px;

  .info {
    margin-top: 24px;
    > span {
      > i {
        color: $info;
        margin-right: 4px;
        ::before {
          font-size: 16px;
        }
      }
    }
  }

  .tableContainer {
    padding-left: 0px;
    padding-right: 0px;

    table {
      thead {
        background-color: $gray200;
      }
      tbody {
        tr {
          td {
            padding-top: 16px;
            padding-bottom: 16px;
            font: $paragraph;
            letter-spacing: 0px;
            color: $gray700;
          }
        }
      }
    }

    th {
      font: $small-label-semi-bold;
      color: $gray800 !important;
      padding-top: 24px;
      padding-bottom: 24px;
    }

    .tableTitle {
      color: $gray700;
      font-weight: bold;
      padding-bottom: 16px;
      font: $heading6;

      .tableDate {
        color: $gray500;
        font-size: 13px;
        font: $small-label-semi-bold;
      }
      .tableDot {
        color: $gray500;
        word-spacing: 4px;
      }
    }

    .tableRows {
      display: flex;
      flex-direction: row;

      padding-top: 16px;
      padding-bottom: 16px;

      .rowLabel {
        font-weight: bold;
      }

      .iconStyle {
        font-size: 16px;
        padding-right: 8px;
      }
    }
  }
}

.column {
  &:first-child {
    padding-left: 20px;
    width: 25%;
  }

  &:not(:first-child):not(:last-child) {
    width: calc((100% - 25% - 80px) / 4);
  }

  &:last-child {
    width: 80px;
    padding-left: 0;
  }

  .iconStyle {
    padding-right: 8px;
    color: $primary;
    width: 32px;
    height: 24px;
  }

  .editIcon {
    color: #999999;
    &::before {
      font-size: 24px;
    }
    cursor: pointer;
  }

  .trashIcon {
    color: #999999;
    &::before {
      font-size: 24px;
    }
    cursor: pointer;
    margin-left: 5px;
  }
}

.syncIcon {
  line-height: 24px !important;
  padding-right: 4px;
}

.timeBlock {
  color: $primary;
  white-space: nowrap;
}

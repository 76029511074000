@import '../../../../../../../../../styles/variables';

.buttonGroup {
  button {
    background: white;
    margin-top: 4px;
    margin-bottom: 20px;
    padding: 6px 40px;
    border: 1px solid $gray300;
    box-sizing: border-box;
    border-radius: 4px 0px 0px 4px;
    font-weight: 500;
    white-space: nowrap;
    transition: all 0.25s ease;
    text-align: center;
    font: $paragraph;
    color: $gray800;

    &:hover,
    &:active,
    &.isSelected {
      outline: none;
      box-shadow: none;
      transition: all 0.25s ease;
      border-color: $primary !important;
      background-color: white !important;
      color: $primary;
      z-index: 10;
    }

    &:focus {
      outline: none;
      box-shadow: none;
      background-color: white !important;
    }

    &:last-child {
      border-radius: 0px 4px 4px 0px;
    }
    &.isDisable {
      outline: none;
      box-shadow: none;
      background: $gray300 0% 0% no-repeat padding-box !important;
      border: 1px solid $gray300 !important;
      color: white !important;
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  opacity: 0.9;
  background: #000000 0% 0% no-repeat padding-box;
}

.tooltip .tooltiptext {
  opacity: 0;
  width: 130px;
  background-color: black;
  color: $white;
  text-align: center;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 11px;
  font-family: Nunito;
  top: -39px;
  left: -11px;
  position: absolute;
  transition: 0.3s;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  opacity: 1;
}

//Core files
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import 'variables';

// use theme (uncomment if want to use other theme)
@import 'themes/default';
// @import "themes/dark";
// @import "themes/modern";
// @import "themes/modern-dark";
// @import "themes/creative";
// @import "themes/cretive-dark";

@import '../../node_modules/bootstrap/scss/bootstrap';

// Structure
@import 'layouts/responsive';
@import 'layouts/general';
@import 'layouts/left-menu';
@import 'layouts/topbar';
@import 'layouts/right-sidebar';
@import 'layouts/page-head';
@import 'layouts/footer';
@import 'layouts/horizontal-nav'; // horizontal nav

//Fonts
@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-Light.eot');
  src: local('Nunito Light'), local('Nunito-Light'), url('./fonts/Nunito-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Nunito-Light.woff') format('woff'), url('./fonts/Nunito-Light.ttf') format('truetype'),
    url('./fonts/Nunito-Light.svg#Roboto') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-Regular.eot');
  src: local('Nunito Regular'), local('Nunito-Regular'),
    url('./fonts/Nunito-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Nunito-Regular.woff') format('woff'), url('./fonts/Nunito-Regular.ttf') format('truetype'),
    url('./fonts/Nunito-Regular.svg#Roboto') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-SemiBold.eot');
  src: local('Nunito SemiBold'), local('Nunito-SemiBold'),
    url('./fonts/Nunito-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Nunito-SemiBold.woff') format('woff'), url('./fonts/Nunito-SemiBold.ttf') format('truetype'),
    url('./fonts/Nunito-SemiBold.svg#Roboto') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-Bold.eot');
  src: local('Nunito Bold'), local('Nunito-Bold'), url('./fonts/Nunito-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Nunito-Bold.woff') format('woff'), url('./fonts/Nunito-Bold.ttf') format('truetype'),
    url('./fonts/Nunito-Bold.svg#Roboto') format('svg');
  font-weight: 700;
  font-style: normal;
}

//Components
// @import "custom/components/mixins";
// @import "custom/components/accordions";
// @import "custom/components/avatar";
// @import "custom/components/breadcrumb";
// @import "custom/components/buttons";
// @import "custom/components/badge";
// @import "custom/components/card";
// @import "custom/components/dropdown";
@import './components/forms';
// @import "custom/components/modal";
// @import "custom/components/nav";
// @import "custom/components/pagination";
// @import "custom/components/popover";
// @import "custom/components/print";
// @import "custom/components/progress";
// @import "custom/components/reboot";
// @import "custom/components/ribbons";
// @import "custom/components/switch";
// @import "custom/components/tables";
@import './components/type';
// @import "custom/components/utilities";
// @import "custom/components/widgets";
// @import "custom/components/social";
// @import "custom/components/steps";
// @import "custom/components/preloader";
// @import "custom/components/hero";

//Pages
// @import "custom/pages/authentication";
// @import "custom/pages/components-demo";
// @import "custom/pages/error";
// @import "custom/pages/faq";
// @import "custom/pages/maintenance";
// @import "custom/pages/tasks";
// @import "custom/pages/email";
// @import "custom/pages/timeline";

// Vendors
// @import "./node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min";

// Plugins
// @import "custom/plugins/apexcharts";
// @import "custom/plugins/britechart";
// @import "custom/plugins/calendar";
// @import "custom/plugins/chartjs";
// @import "custom/plugins/datatable";
@import './plugins/datepicker';
// @import "custom/plugins/draft-wysiwyg";
// @import "custom/plugins/dropzone";
// @import "custom/plugins/maps";
// @import "custom/plugins/metisMenu";
// @import "custom/plugins/rangeslider";
// @import "custom/plugins/react-select";
// @import "custom/plugins/slimscroll";
// @import "custom/plugins/simplemde";
// @import "custom/plugins/typehead";
// @import "custom/plugins/frappe-gantt";

// TODO: this is from old index.css at global level, should we keep this?

body {
  margin: 0;
  font-family: 'Nunito';
}

/*********************** CUSTOM GLOBAL STYLING FOR PROJECT *****************************/
$custom-colors: ();
$custom-colors: map-merge(
  (
    'branding-blue': #00a9eb,
    'placeholder-grey': #f1f3fa,
    'disable-grey': #adb5bd,
    'border-grey': #c1c8d0,
    'default-black': #313a46,
    'alert-red': #cb2e25,
  ),
  $custom-colors
);

$large-screen: xl;
$small-screen: esm, sm, md, lg;

.btn-secondary {
  border: #cccccc;
}

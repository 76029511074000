@import '../../../../../../../styles/variables';

.container {
  display: block;
  width: 170px;
  height: 170px;

  .wrapper {
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
    border: 1px dashed $gray500;
    position: relative;

    .uploader {
      width: 100%;
      height: 100%;
      position: absolute;
      cursor: pointer;
    }

    .picture {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
}

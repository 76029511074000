@import '../../../../../styles/variables';

.mainValue {
  font: $heading6 !important;
  color: $gray700 !important;
}

.subValue {
  font: $small-paragraph !important;
  color: $success !important;
  margin-left: 4px;

  &.isMinus {
    color: $danger !important;
  }
}

.inCard {
  margin-top: 14px;
  .mainValue {
    font: $heading1 Nunito !important;
    color: #ff7b1a !important;
    display: block;
  }
  .subValue {
    margin-top: 8px;
    font: $heading6 !important;
    display: inline-block;
  }
  .subString {
    font: $paragraph;
    color: $gray800;
    display: inline;
  }
}

@import '../../../../../styles/variables';

.dateBlock {
  display: inline-block;
  padding-right: 4px;
}
.timeBlock {
  color: $primary;
  white-space: nowrap;
  display: inline-block;
}
.nowrap {
  white-space: nowrap;
}

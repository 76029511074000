@import '../../../../../../../styles/variables';

.campaignContainer {
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 8px #00000029;
  color: $gray800;
  border-radius: 4px;
}

.imageWrapper {
  position: relative;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  height: 184px;
  background: no-repeat center;
  background-size: cover;
}

.infoContainer {
  padding: 16px;

  > div:nth-child(1) {
    margin-bottom: 16px;
  }
}

.infoTitle {
  font: normal normal bold 16px/24px Nunito;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  span {
    margin-left: 8px;
  }
}

.infoContent {
  display: flex;

  div {
    flex: 1 1 auto;
    font-size: 16px;
  }
}

.valueRateGroup {
  display: flex;
  align-items: center;

  .value {
    color: $gray700;
    font: normal normal bold 16px/24px Nunito;
    margin-right: 4px;
  }
}

.actionBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $gray300;
  padding: 21px 16px;
}

.status {
  text-align: center;
  padding: 4px 8px;
  border-radius: 10px;
  font: normal normal 600 14px/18px Nunito;
}

.statusActivation {
  color: #004085;
  background-color: #e0efff;
}

.statusReview {
  color: #856404;
  background-color: #fff8e1;
}

.viewDetail {
  color: $info;
  padding: 0px 16px;
}

.actionButtonGroup {
  display: flex;
  align-items: center;
  font-size: 16px;
}

button.actionButton {
  min-width: 140px;
  padding: 8px 0px;
  color: $white;
}

button.activateButton {
  background-color: $success;
}

button.editButton {
  background-color: $info;
}

button.deniedButton {
  background-color: $danger;
}

@import '../../../styles/index.scss';

.inputMain {
  border-radius: 4px !important;
  background-image: none !important;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.invalidAddon {
  border-color: $danger !important;
}

@import '../../../../styles/variables';
.dividerStyle {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 1rem;

  div {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalFooter {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.footerButtonStyle {
  display: flex;
  justify-content: flex-end;
}

.cancelButtonStyle {
  margin-right: 1rem;
  font: $paragraph;
  float: inline-start;

  button {
    border-color: darkgray !important;
    min-width: 140px;
    color: $gray700;
    &:focus,
    &:hover {
      color: $gray700;
    }
  }
}

.saveButtonStyle {
  font: $paragraph;

  button {
    background-color: $primary !important;
    border-color: $primary !important;
    min-width: 140px;
  }
}

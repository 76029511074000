.textWrapper {
  margin-top: 8px;
  .label {
    text-align: left;
    font: normal normal normal 16px/24px Nunito;
    color: #1f1f1f;
  }

  .value {
    text-align: left;
    font: normal normal bold 16px/24px Nunito;
    color: #1f1f1f;
  }
  &.spaceTop {
    margin-top: 18px;
  }
  &.inline {
    display: inline-block;
  }
}

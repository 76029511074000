$parsley-color: #155724;
$bay-leaf-color: #86bb91;
$colonial-white: #ffeeba;
$geyser: #dee2e6;
$gin-fizz: #fff8e1;
$gray100: #f6f6f6;
$gray200: #eeeeee;
$gray300: #cccccc;
$gray400: #bcbcbc;
$gray500: #999999;
$gray600: #555555;
$gray700: #333333;
$gray800: #1f1f1f;
$muted: #6c757d;
$warning: #ffc107;
$danger: #dc3545;
$success: #28a745;
$info: #007bff;
$teriary: #fff5e9;
$primary: #ff7b1a;
$white: #ffffff;
$secondary: #323a46;
$yukon-gold: #856404;
$white-background: #ffffff 0% 0% no-repeat padding-box;
$heading1: normal normal bold 40px/60px Nunito;
$heading2: normal normal bold 32px/48px Nunito;
$heading3: normal normal bold 28px/42px Nunito;
$heading4: normal normal bold 24px/36px Nunito;
$heading45: normal normal bold 18px/36px Nunito;
$heading5: normal normal bold 20px/30px Nunito;
$heading6: normal normal bold 16px/24px Nunito;
$large-label: normal normal 600 18px/24px Nunito;
$medium-label: normal normal 600 16px/10px Nunito;
$small-label-bold: normal normal bold 14px/18px Nunito;
$small-label-semi-bold: normal normal 600 14px/18px Nunito;
$paragraph: normal normal normal 16px/24px Nunito;
$bold-paragraph: normal normal 600 16px/24px Nunito;
$small-paragraph: normal normal normal 12px/20px Nunito;

@import '../../styles/variables';

.button {
  height: 38px;
  border: 1px solid $gray300;
  border-radius: 4px;
}

.button:focus {
  outline: none !important;
  box-shadow: none !important;
}
